import { Routes } from '@angular/router';

import { errorRoutes } from '../../not-found-error/not-found-error.page';
import { JOB_PAGE_URL } from './pages/job/job.page';
import { SAMPLE_PAGE_URL } from './pages/sample/sample.page';

export const jobsRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./jobs-and-tests.page').then((m) => m.JobsAndTestsOverviewPage),
    title: 'Jobs & tests overview  - Alpha Scientific',
  },
  {
    path: JOB_PAGE_URL,
    loadComponent: () => import('./pages/job/job.page').then((m) => m.JobPage),
    title: ({ params }) =>
      `${params['jobId'] || 'Loading..'} - Job - Alpha Scientific`,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/job/job-details/job-details.component').then(m => m.JobDetailsComponent),
      },
      {
        path: 'samples',
        loadComponent: () =>
          import('./pages/job/job-samples/job-samples.component').then(m => m.JobSamplesComponent),
      },
      {
        path: 'documents',
        loadComponent: () =>
          import('./pages/job/job-documents/job-documents.component').then(m => m.JobDocumentsComponent),
      },
    ],
  },
  {
    path: `${JOB_PAGE_URL}/${SAMPLE_PAGE_URL}`,
    loadComponent: () =>
      import('./pages/sample/sample.page').then((m) => m.SamplePage),
    title: 'Sample - Alpha Scientific',
  },
  ...errorRoutes,
];
