import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { Store } from '@ngrx/store';
import { filter, Observable } from "rxjs";

import { BreadcrumbsComponent } from '../../../../../components/breadcrumbs/breadcrumbs.component';
import { SegmentsComponent } from '../../../../../components/segments/segments.component';
import { Job } from '../../../../../interfaces/job.interface';
import { JobActions } from '../../../../../store/actions/job.actions';
import { selectJobById, selectJobStatePending, } from '../../../../../store/selectors/job.selector';
import { JOBS_AND_TESTS_PAGE_URL } from '../../jobs-and-tests.page';

export const JOB_PAGE_URL = 'job/:jobId';

@Component({
    templateUrl: './job.page.html',
    imports: [
        CommonModule,
        SegmentsComponent,
        BreadcrumbsComponent,
        ReactiveFormsModule,
        RouterOutlet,
    ]
})
export class JobPage {
  // Router variables.
  public jobId: string | null = null;

  // Job variables.
  public job$: Observable<Job | undefined>;
  public isJobPending$: Observable<boolean>;

  // Here we will calculate which sub route (if any) we are accessing and set the view accordingly,
  // this is for the sub navigation pills. If we are on the default route set the view to 'details' (default).
  // Otherwise, ensure that the pills value matches the child route path in the template and set it from the route.
  public view = 'details';

  public showDeleteJobModal = false;

  public constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store,
  ) {
    // Get the organisation and job params from the URL. Default to an empty string if not available.
    this.jobId = this.route.snapshot.paramMap.get('jobId') ?? '';

    // Dispatch action to get the job from the API.
    this.store.dispatch(JobActions.get({ id: this.jobId }));

    // Select the job and it's loading state.
    this.job$ = this.store.select(selectJobById(this.jobId));
    this.isJobPending$ = this.store.select(selectJobStatePending);

    // Set the view variable accordingly. This is for the sub navigation
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.view = this.route.snapshot.firstChild?.routeConfig?.path || 'details';
      });
  }

  /**
   * View a sample
   * @param id
   */
  public async viewSample(id: string): Promise<void> {
    await this.router.navigateByUrl(
      `${JOBS_AND_TESTS_PAGE_URL}/job/${this.jobId}/sample/${id}`,
    );
  }

  /**
   * Go to child route (used for sub navigation).
   * @param route
   */
  public goToChild(route: string) {
    this.view = route;
    this.router.navigate( route === 'details' ? ['.'] : [route], { relativeTo: this.route } );
  }
}
