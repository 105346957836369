import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Asset } from "../../interfaces/asset.interface";

export const AssetActions = createActionGroup({
  source: 'Assets',
  events: {
    'Get for job': props<{ organisationId?: string, jobId: string, admin?: boolean }>(),
    'Get for job success': props<{ assets: Asset[] }>(),
    'Get for job failure': props<{ error: HttpErrorResponse }>(),

    'Clear state': emptyProps(),
  },
});
