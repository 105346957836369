import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';

import { Alert, AlertType } from '../interfaces/alert.interface';
import { AlertActions } from '../store/actions/alert.action';

function camelCaseToTitleCase(str: string) {
  return str
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
}

function createGenericErrorAlert({
  error,
}: {
  error: HttpErrorResponse;
}): Alert {
  const errors: [string, string][] = [];

  if (error?.error?.errors?.length) {
    error.error.errors.forEach(
      (errorMessages: Record<string, Record<string, string>>) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(errorMessages).forEach(([_key, value]) => {
          Object.entries(value).forEach((errorMessage) =>
            errors.push([
              errorMessage[0],
              camelCaseToTitleCase(errorMessage[1]),
            ]),
          );
        });
      },
    );
  }

  return {
    id: uuidv4(),
    message: error?.error?.message ? error.error.message : 'An error occurred',
    errors: errors,
    type: 'error',
  };
}

export function handleHttpError(error: { error: HttpErrorResponse }): Action {
  return AlertActions.create({ alert: createGenericErrorAlert(error) });
}

export function createCustomAlert(opts?: {
  message?: string;
  errors?: [string, unknown][];
  type?: AlertType;
}): Action {
  return AlertActions.create({
    alert: {
      id: uuidv4(),
      message: opts?.message ?? 'Something went wrong. Please try again later',
      errors: Object.entries(opts?.errors ?? {}),
      type: opts?.type ?? 'error',
    },
  });
}
