import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, Observable, Subscription } from 'rxjs';

import { BreadcrumbsComponent } from '../../../../../components/breadcrumbs/breadcrumbs.component';
import { ButtonComponent } from '../../../../../components/button/button.component';
import { InputComponent } from '../../../../../components/input/input.component';
import { LoadingComponent } from '../../../../../components/loading/loading.component';
import {
  Sample,
  SampleUpdate,
} from '../../../../../interfaces/sample.interface';
import { OptionActions } from '../../../../../store/actions/option.actions';
import { SampleActions } from '../../../../../store/actions/sample.actions';
import {
  selectAllSampleSuites,
  selectSampleMatrices,
  selectSampleTypes,
} from '../../../../../store/selectors/option.selector';
import {
  selectSampleById,
  selectSampleStatePending,
} from '../../../../../store/selectors/sample.selector';
import { selectActiveOrganisationUserRole } from '../../../../../store/selectors/user.selector';
import { JOBS_AND_TESTS_PAGE_URL } from '../../jobs-and-tests.page';

export const SAMPLE_PAGE_URL = 'sample/:sampleId';

@Component({
  templateUrl: './sample.page.html',
  imports: [
    NgSelectComponent,
    CommonModule,
    ReactiveFormsModule,
    BreadcrumbsComponent,
    ButtonComponent,
    InputComponent,
    LoadingComponent,
  ],
})
export class SamplePage implements OnInit, OnDestroy {
  public jobId = '';
  public sampleId = '';
  public sample$!: Observable<Sample | undefined>;
  public sampleSub!: Subscription;
  public sample: Sample | undefined = undefined;

  public sampleTypes$ = this.store.pipe(select(selectSampleTypes));
  public matrixTypes$ = this.store.pipe(select(selectSampleMatrices));
  public matrixTypes: { id: string; name: string }[] = [];
  public testSuites$ = this.store.pipe(select(selectAllSampleSuites));
  public testSuites: { id: string; name: string }[] = [];
  public testSuitesSub!: Subscription;
  public matrixTypesSub!: Subscription;

  public canEdit = true;

  public editing = {
    sampleDescription: false,
    type: false,
    matrix: false,
    status: false,
    suite: false,
    notes: false,
  };

  public readonly form = new FormGroup({
    sampleDescription: new FormControl(''),
    type: new FormControl(''),
    matrix: new FormControl(''),
    status: new FormControl(''),
    suite: new FormControl(''),
    notes: new FormControl(''),
  });

  public loading = false;

  private readonly loading$ = this.store
    .select(selectSampleStatePending)
    .subscribe((loading) => (this.loading = loading));

  public constructor(
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly titleService: Title,
  ) {}

  public async ngOnInit(): Promise<void> {
    const role = await firstValueFrom(
      this.store.select(selectActiveOrganisationUserRole),
    );

    const { jobId, sampleId } = await firstValueFrom(this.route.params);

    if (!jobId || !sampleId || !role) {
      await this.router.navigateByUrl(JOBS_AND_TESTS_PAGE_URL);
      return;
    }

    this.jobId = jobId;
    this.sampleId = sampleId;

    this.store.dispatch(
      SampleActions.loadSample({
        jobId,
        sampleId: this.sampleId,
      }),
    );

    this.sample$ = this.store.pipe(select(selectSampleById(sampleId)));
    this.sampleSub = this.sample$.subscribe(async (sample) => {
      this.sample = sample;
      await this.updateCanEdit();
      const { sampleDescription, type, matrix, suite, notes } = sample || {};
      this.form.patchValue({
        sampleDescription,
        type,
        matrix,
        suite,
        notes,
      });

      this.titleService.setTitle(
        `${sample?.id ?? 'Loading..'} - ${jobId} - Jobs & tests overview - Alpha Scientific`,
      );
    });

    this.testSuitesSub = this.testSuites$.subscribe((params) => {
      this.testSuites = [];
      this.testSuites = params.map(({ code, description }) => ({
        id: code,
        name: description,
      }));
    });
    this.matrixTypesSub = this.matrixTypes$.subscribe((params) => {
      this.matrixTypes = [];
      this.matrixTypes = params.map(({ id, description }) => ({
        id,
        name: description,
      }));
    });

    await this.updateCanEdit();
  }

  public ngOnDestroy(): void {
    this.testSuitesSub?.unsubscribe();
    this.matrixTypesSub?.unsubscribe();
    this.sampleSub?.unsubscribe();
    this.loading$?.unsubscribe();
  }

  public async updateCanEdit(): Promise<void> {
    const role = await firstValueFrom(
      this.store.select(selectActiveOrganisationUserRole),
    );

    let canEdit = true;

    if (this.sample?.status !== 'DRAFT') {
      canEdit = false;
    }

    if (!role || role === 'USER') {
      canEdit = false;
    }

    this.canEdit = canEdit;
  }

  public async editMode(
    mode:
      | 'sampleDescription'
      | 'type'
      | 'status'
      | 'matrix'
      | 'suite'
      | 'notes',
  ): Promise<void> {
    switch (mode) {
      case 'type':
        this.store.dispatch(OptionActions.loadSampleTypes());
        break;
      case 'matrix':
        this.store.dispatch(OptionActions.loadSampleMatrices());
        break;
      case 'suite':
        this.store.dispatch(OptionActions.loadSampleSuites());
        break;
    }

    this.clearEditMode();

    this.editing[mode] = true;
  }

  public clearEditMode(): void {
    this.editing = {
      sampleDescription: false,
      type: false,
      matrix: false,
      status: false,
      suite: false,
      notes: false,
    };
  }

  public updateSampleDescription(): void {
    const { sampleDescription } = this.form.value;

    if (sampleDescription) {
      this.store.dispatch(
        SampleActions.updateSample({
          jobId: this.jobId,
          sampleId: this.sampleId,
          sample: {
            ...this.toSampleUpdate(),
            sampleDescription,
          },
        }),
      );
    }

    this.clearEditMode();
  }

  public updateSampleType(): void {
    const { type } = this.form.value;

    if (type) {
      this.store.dispatch(
        SampleActions.updateSample({
          jobId: this.jobId,
          sampleId: this.sampleId,
          sample: {
            ...this.toSampleUpdate(),
            type,
          },
        }),
      );
    }

    this.clearEditMode();
  }

  public updateSampleMatrix(): void {
    const { matrix } = this.form.value;

    if (matrix) {
      this.store.dispatch(
        SampleActions.updateSample({
          jobId: this.jobId,
          sampleId: this.sampleId,
          sample: {
            ...this.toSampleUpdate(),
            matrix,
          },
        }),
      );
    }

    this.clearEditMode();
  }

  public updateSampleSuiteCode(): void {
    const { suite } = this.form.value;

    if (suite) {
      this.store.dispatch(
        SampleActions.updateSample({
          jobId: this.jobId,
          sampleId: this.sampleId,
          sample: {
            ...this.toSampleUpdate(),
            suiteCode: suite,
          },
        }),
      );
    }

    this.clearEditMode();
  }

  public updateSampleNotes(): void {
    const { notes } = this.form.value;

    if (notes) {
      this.store.dispatch(
        SampleActions.updateSample({
          jobId: this.jobId,
          sampleId: this.sampleId,
          sample: {
            ...this.toSampleUpdate(),
            notes,
          },
        }),
      );
    }

    this.clearEditMode();
  }

  private toSampleUpdate(): SampleUpdate {
    const { suite, ...rest } = this.sample!;
    return {
      ...rest,
      suiteCode: suite,
    };
  }
}
