import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { lastValueFrom, take } from 'rxjs';

import { LoadingComponent } from '../../../../../components/loading/loading.component';
import { StatusesEnum } from '../../../../../interfaces/status.interface';
import { UserRoleEnum } from '../../../../../interfaces/user.interface';
import { OrganisationActions } from '../../../../../store/actions/organisation.actions';
import {
  selectOrganisationOrderOverview,
  selectOrganisationOrderOverviewStatePending,
  selectOrganisationUserOverview,
  selectOrganisationUserOverviewStatePending,
} from '../../../../../store/selectors/organisation.selector';
import { selectActiveOrganisationUserRole } from '../../../../../store/selectors/user.selector';

@Component({
  selector: 'app-organisation-overview',
  templateUrl: './organisation-overview.component.html',
  imports: [CommonModule, LoadingComponent, RouterLink],
})
export class OrganisationOverviewComponent implements OnInit {
  public statuses = StatusesEnum;
  public userRoles = UserRoleEnum;
  public userRole$ = this.store.pipe(select(selectActiveOrganisationUserRole));
  public userOverviewIsPending$ = this.store.pipe(
    select(selectOrganisationUserOverviewStatePending),
  );
  public orderOverviewIsPending$ = this.store.pipe(
    select(selectOrganisationOrderOverviewStatePending),
  );
  public userOverview$ = this.store.pipe(
    select(selectOrganisationUserOverview),
  );
  public orderOverview$ = this.store.pipe(
    select(selectOrganisationOrderOverview),
  );

  public constructor(private readonly store: Store) {}

  public async ngOnInit(): Promise<void> {
    const userRole = await lastValueFrom(
      this.store.pipe(select(selectActiveOrganisationUserRole), take(1)),
    );

    if (userRole !== UserRoleEnum.USER) {
      this.store.dispatch(OrganisationActions.getUserOverview());
    }

    this.store.dispatch(OrganisationActions.getOrderOverview());
  }
}
