import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { fromUnixTime } from 'date-fns/fromUnixTime';
import { firstValueFrom, Observable, Subscription } from 'rxjs';

import { BreadcrumbsComponent } from '../../../../../components/breadcrumbs/breadcrumbs.component';
import { DividerComponent } from '../../../../../components/divider/divider.component';
import { IconComponent } from '../../../../../components/icon/icon.component';
import { LoadingComponent } from '../../../../../components/loading/loading.component';
import {
  Organisation,
  OrganisationUser,
} from '../../../../../interfaces/organisation.interface';
import { UserStatus } from '../../../../../interfaces/status.interface';
import {
  UserRole,
  UserSummary,
} from '../../../../../interfaces/user.interface';
import { OrganisationUsersActions } from '../../../../../store/actions/organisation-users.actions';
import { OrganisationsActions } from '../../../../../store/actions/organisations.actions';
import {
  selectOrganisationUserById,
  selectPending as selectUserPending,
} from '../../../../../store/selectors/organisation-users.selectors';
import {
  selectOrganisationById,
  selectPending as selectOrganisationPending,
} from '../../../../../store/selectors/organisations.selector';
import { ADMIN_JOBS_PAGE_URL } from '../../../jobs/jobs.page';
import { AdminRemoveUserModalComponent } from '../../components/remove-user-modal/remove-user-modal.component';
import { AdminResetPasswordModalComponent } from '../../components/reset-password-modal/reset-password-modal.component';
import { ADMIN_USERS_PAGE_URL } from '../../users.page';

export const ADMIN_USER_DETAILS_PAGE_URL =
  'organisations/:organisationId/users/:userId';

@Component({
  templateUrl: './user-details.page.html',
  imports: [
    CommonModule,
    BreadcrumbsComponent,
    LoadingComponent,
    DividerComponent,
    IconComponent,
    AdminResetPasswordModalComponent,
    AdminRemoveUserModalComponent,
  ],
})
export class AdminUserDetailsPage implements OnInit, OnDestroy {
  public user$!: Observable<OrganisationUser | undefined>;
  public user: {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    createdAt: Date;
    createdBy?: UserSummary;
    status: UserStatus;
    role: UserRole;
  } | null = null;
  public isOrganisationPending$ = this.store.select(selectOrganisationPending);
  public isUserPending$ = this.store.select(selectUserPending);
  public isUserInvitePending = false;
  public organisation!: Organisation;
  public showModal = '';
  private organisationSubscription!: Subscription;
  private organisationUserSubscription!: Subscription;

  public constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    private readonly router: Router,
    private readonly titleService: Title,
  ) {}

  public async ngOnInit(): Promise<void> {
    const params = await firstValueFrom(this.route.params);
    const userId = params['userId'];
    const organisationId = params['organisationId'];

    if (!userId || !organisationId) {
      await this.router.navigateByUrl(ADMIN_USERS_PAGE_URL);
    }

    this.store.dispatch(OrganisationsActions.get({ id: organisationId }));

    this.store.dispatch(
      OrganisationUsersActions.get({
        organisationId,
        userId,
        admin: true,
      }),
    );

    this.organisationSubscription = this.store
      .select(selectOrganisationById(organisationId))
      .subscribe((org) => {
        if (org) {
          this.organisation = org;
        }
      });
    this.organisationUserSubscription = this.store
      .select(selectOrganisationUserById(userId))
      .subscribe((user) => {
        if (user?.profile) {
          this.isUserInvitePending = user.status === 'INVITED';

          this.user = {
            id: user.userId,
            firstName: user.profile.firstName,
            lastName: user.profile.lastName,
            phoneNumber: user.profile.phoneNumber,
            email: user.profile.email,
            createdAt: fromUnixTime(user.profile.createdAt),
            createdBy: user?.createdBySummary || undefined,
            status: user.status,
            role: user.role,
          };

          const username =
            this.user?.firstName.length && this.user?.lastName.length
              ? `${this.user.firstName} ${this.user.lastName}`
              : '';
          this.titleService.setTitle(
            `${username || 'Loading..'} - User management - Admin - Alpha Scientific`,
          );
        }
      });
  }

  public ngOnDestroy(): void {
    this.organisationUserSubscription?.unsubscribe();
    this.organisationSubscription?.unsubscribe();
  }

  public async closedAdminRemoveUserModal(): Promise<void> {
    this.showModal = '';
    await this.router.navigateByUrl(ADMIN_USERS_PAGE_URL);
  }

  public async viewUserJobs() {
    if (this.user && this.user.status === 'ACTIVE') {
      await this.router.navigateByUrl(
        `admin/${ADMIN_JOBS_PAGE_URL}?user=${this.user.id}`,
      );
    }
  }
}
