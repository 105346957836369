<app-modal-layout (clickedOutside)="close()" *ngIf="show">
  <div class="rounded-2xl bg-white p-14">
    <ng-container *ngIf="!!error">
      <div
        class="relative mb-5 rounded border border-red-200 bg-red-100 px-4 py-3 text-red-700"
        role="alert"
      >
        <strong class="font-bold" i18n>Error!</strong>
        <span class="block sm:inline">
          <ng-container
            *ngIf="error?.error?.message; else showGenericErrorAlert"
          >
            {{ error.error.message }}
          </ng-container>
          <ng-template #showGenericErrorAlert i18n
            >Something went wrong. Please try again later</ng-template
          >
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="view === 'initial'; else responseView">
      <div class="mt-6">
        <h2 i18n class="mb-4 text-3xl font-medium">
          Invite {{ inviteAdmin ? "an admin" : "a" }} user
        </h2>
        <p i18n class="mb-8">
          Please fill out the form below to set up your Alpha Scientific
          account.
        </p>
      </div>

      <form (submit)="onSubmit()" [formGroup]="form">
        <app-input
          [form]="form"
          controlName="firstName"
          name="firstName"
          i18n-label
          label="First name"
          i18n-placeholder
          placeholder="First name"
        />

        <app-input
          [form]="form"
          controlName="lastName"
          name="lastName"
          i18n-label
          label="Last name"
          i18n-placeholder
          placeholder="Last name"
        />

        <app-input
          [form]="form"
          controlName="phoneNumber"
          name="phoneNumber"
          i18n-label
          label="Phone number"
          i18n-placeholder
          placeholder="Phone number"
        />

        <app-input
          [form]="form"
          type="email"
          controlName="email"
          name="email"
          i18n-label
          label="Email address"
          i18n-placeholder
          placeholder="Email address"
        />

        <div class="mt-8 flex flex-col">
          <app-button
            type="submit"
            i18n-text
            text="Invite user"
            classes="w-full"
            [disabled]="!form.valid || pending"
          />
          <app-button
            i18n-text
            text="Cancel"
            (click)="closed.emit()"
            (keydown.enter)="closed.emit()"
            classes="w-full mt-4"
          />
        </div>
      </form>
    </ng-container>

    <ng-template #responseView>
      <ng-container *ngIf="pending">
        <div class="mt-6 text-center">
          <h2 i18n class="mb-4 text-3xl font-medium">Sending invitation</h2>
          <p i18n class="mb-8">Please wait.</p>
        </div>
      </ng-container>

      <ng-container *ngIf="!pending && !error">
        <div class="mt-6 text-center">
          <div
            class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
          >
            <div class="h-16">
              <app-icon icon="send" />
            </div>
          </div>
        </div>
        <div class="mt-6 text-center">
          <h2 i18n class="mb-4 text-3xl font-medium">Invitation sent</h2>
          <p i18n class="mb-8">
            Your invitation to '{{ form.value.firstName }}
            {{ form.value.lastName }}' has been successfully sent.
          </p>
        </div>
        <div class="mt-8 flex flex-col">
          <button
            (click)="close()"
            (keydown.enter)="close()"
            i18n
            class="text-alpha-blue"
          >
            Close message
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!pending && error">
        <div class="mt-6 text-center">
          <div
            class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
          >
            <div class="h-16">
              <app-icon icon="warning" />
            </div>
          </div>
        </div>
        <div class="mt-6 text-center">
          <h2 i18n class="mb-4 text-3xl font-medium">Error</h2>
          <p i18n class="mb-8">
            The invitation could not be sent, please try again.
          </p>
        </div>
        <div class="mt-8 flex justify-between">
          <button
            (click)="close()"
            (keydown.enter)="close()"
            i18n
            class="text-alpha-blue"
          >
            Cancel
          </button>
          <button
            (click)="view = 'initial'"
            (keydown.enter)="view = 'initial'"
            i18n
            class="text-alpha-blue"
          >
            Retry
          </button>
        </div>
      </ng-container>
    </ng-template>
  </div>
</app-modal-layout>
