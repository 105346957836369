<div class="flex flex-col md:flex-row md:justify-between">
  <div>
    <h1 class="text-3xl font-medium" i18n>
      Order: {{ (job$ | async)?.id || 'Loading...' }}
    </h1>
    <p>{{ (job$ | async)?.clientReference || 'Loading...' }}</p>
  </div>
</div>

<div class="mb-4 mt-3">
  <app-breadcrumbs
    [segments]="[
    {title: 'Jobs & tests overview ', url: 'jobs-and-tests'},
    {title: (job$ | async)?.id || 'Loading...'}
    ]"
  />
</div>

<app-segments
  [activeValue]="view"
  (clicked)="goToChild($event)"
  [items]="[
  {
    icon: 'details',
    value: 'details',
    title: 'Job details'
  },
  {
    icon: 'result',
    value: 'samples',
    title: 'Samples (' + ((isJobPending$ | async) ? 'Loading...' : ( job$ | async )?.metrics?.noOfSamples) + ')'
  },
  {
    icon: 'details',
    value: 'documents',
    title: 'Documents (' + ((isJobPending$ | async) ? 'Loading...' : ( job$ | async )?.metrics?.noOfAssets) + ')'
  },
]"
/>

<router-outlet></router-outlet>
