import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIResponse } from '../interfaces/api.interface';
import {
  Job,
  JobCreate,
  JobSearchParams,
  JobUpdate,
  JobUserSearchParams,
} from '../interfaces/job.interface';
import { UserSummary } from '../interfaces/user.interface';
import { queryParamString } from '../utils/query-param-string';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  public constructor(private readonly api: ApiService) {}

  public create(organisationId: string, data: JobCreate): Observable<Job> {
    return this.api.post<Job, JobCreate>(
      `organisations/${organisationId}/orders`,
      data,
    );
  }

  public get(organisationId: string, jobId: string): Observable<Job> {
    return this.api.get<Job>(
      `admin/organisations/${organisationId}/orders/${encodeURIComponent(jobId)}`,
    );
  }

  public search(params?: JobSearchParams): Observable<APIResponse<Job[]>> {
    return this.api.get<APIResponse<Job[]>>(
      queryParamString('admin/orders', params),
    );
  }

  public searchUsers(
    params: JobUserSearchParams,
    admin = false,
  ): Observable<APIResponse<UserSummary[]>> {
    // Check if we are requesting the admin, if not then the organsiationId is required.
    if (!admin && !params.organisationId) {
      throw new Error('Organisation ID is required.');
    }

    // Build the path based on if we are admin or not.
    let path = admin
      ? `admin/orders/users`
      : `/organisations/${params.organisationId}/orders/users`;

    // Check if the keyword has been set, if so, add it as a param to the URL.
    if (params.q) {
      path = path + `?q=${params.q}`;
    }

    return this.api.get<APIResponse<UserSummary[]>>(path);
  }

  public update(
    organisationId: string,
    jobId: string,
    data: JobUpdate,
  ): Observable<Job> {
    return this.api.put<Job, JobUpdate>(
      `admin/organisations/${organisationId}/orders/${jobId}`,
      data,
    );
  }
}
