import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { ButtonComponent } from '../../../components/button/button.component';
import { LoadingComponent } from '../../../components/loading/loading.component';
import { OrganisationUser } from '../../../interfaces/organisation.interface';
import { UserStatusEnum } from '../../../interfaces/status.interface';
import { UserSearchParams } from '../../../interfaces/user.interface';
import { UsersActions } from '../../../store/actions/users.actions';
import {
  selectPending,
  selectSearchedUsers,
} from '../../../store/selectors/users.selectors';
import { MenuComponent } from '../../dashboard/user-management/components/menu/menu.component';
import { MenuActionComponent } from '../../dashboard/user-management/components/menu-action/menu-action.component';
import { UsersSearchComponent } from './components/users-search/users-search.component';

export const ADMIN_USERS_PAGE_URL = 'users';

@Component({
  templateUrl: './users.page.html',
  imports: [
    CommonModule,
    ButtonComponent,
    MenuActionComponent,
    MenuComponent,
    LoadingComponent,
    UsersSearchComponent,
  ],
})
export class UsersPage implements OnInit {
  public userMenuOpen = '';
  public readonly users$ = this.store.select(selectSearchedUsers);
  public readonly isPending$ = this.store.select(selectPending);
  protected readonly UserStatusEnum = UserStatusEnum;

  public constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.search({});
  }

  public search(params: UserSearchParams): void {
    this.store.dispatch(UsersActions.search({ params }));
  }

  public async viewUser(user: OrganisationUser): Promise<void> {
    const orgId = user.organisation?.id;
    const userId = user.userId;

    if (orgId && userId) {
      await this.router.navigateByUrl(
        `/admin/users/organisation/${encodeURIComponent(orgId)}/user/${encodeURIComponent(userId)}`,
      );
    }
  }
}
