import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { catchError, from, map, Observable, of, switchMap } from "rxjs";

import { AssetsService } from "../../services/assets.service";
import { AssetActions } from "../actions/asset.actions";

@Injectable()
export class AssetsEffects {
  public constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly service: AssetsService,
  ) {}

  public getForOrder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetActions.getForJob),
      switchMap(({ organisationId, jobId, admin }) =>
        from(this.service.getForJob(jobId, organisationId, admin)).pipe(
          map(({ items }) => AssetActions.getForJobSuccess({ assets: items })),
          catchError((error) => of(AssetActions.getForJobFailure({ error }))),
        ),
      ),
    ),
  );

}
