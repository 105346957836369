<div class="flex flex-col md:flex-row md:justify-between">
  <div>
    <h1 class="text-3xl font-medium" i18n>
      Sample: {{ (sample$ | async)?.id || '' }}
    </h1>
    <p>{{ (sample$ | async)?.clientReference || '' }}</p>
  </div>
</div>

<div class="mb-4 mt-3">
  <app-breadcrumbs
    [segments]="[
    {title: 'Jobs & tests overview ', url: 'jobs-and-tests'},
    {title: 'Job ' + jobId, url: 'jobs-and-tests/job/' + jobId},
    {title: 'Samples', url: 'jobs-and-tests/job/' + jobId + '/samples'},
    {title: sampleId}
    ]"
  />
</div>

<div class="h-full">
  <div *ngIf="sample; else showLoadingSpinner">
    <div>
      <h2 i18n class="text-sm font-light text-gray-600">Sample ID</h2>
      <p class="mb-5 mt-1 text-xl text-black">{{ sample.id }}</p>
    </div>

    <div class="relative max-w-sm">
      <h2
        i18n
        class="text-sm font-light"
        [ngClass]="{
        'text-gray-600': !editing.sampleDescription,
        'text-alpha-red': editing.sampleDescription && form.controls['sampleDescription'].touched && form.controls['sampleDescription'].errors,
      }"
      >
        Sample location
      </h2>

      <p
        *ngIf="!editing.sampleDescription"
        class="mb-5 mt-1 flex items-center pr-6 text-xl text-black"
      >
        {{ sample.sampleDescription }}
        <ng-container
          *ngIf="loading && sample.sampleDescription !== form.value.sampleDescription"
        >
          <div class="ml-4">
            <app-loading size="h-4 w-4" />
          </div>
        </ng-container>
      </p>

      <app-input
        *ngIf="editing.sampleDescription"
        [form]="form"
        controlName="sampleDescription"
      />

      <app-button
        *ngIf="canEdit && !editing.sampleDescription"
        [disabled]="loading && sample.sampleDescription !== form.value.sampleDescription"
        icon="edit"
        iconFill="blue"
        iconHeight="18px"
        classes="p-3 absolute top-1 right-0"
        (click)="editMode('sampleDescription')"
        (keydown.enter)="editMode('sampleDescription')"
      />

      <div *ngIf="editing.sampleDescription" class="mb-4 mt-2">
        <app-button
          i18n-text
          text="Cancel"
          classes="py-2 px-6"
          (click)="clearEditMode()"
          (keydown.enter)="clearEditMode()"
        />
        <app-button
          i18n-text
          [disabled]="!!form.controls['sampleDescription'].errors"
          text="Save"
          type="submit"
          (click)="updateSampleDescription()"
          (keydown.enter)="updateSampleDescription()"
          classes="py-2 px-6  ml-3"
        />
      </div>
    </div>

    <div class="relative max-w-sm" [formGroup]="form">
      <h2
        i18n
        class="text-sm font-light"
        [ngClass]="{
        'text-gray-600': !editing.type,
        'text-alpha-red': editing.type && form.controls['type'].touched && form.controls['type'].errors,
      }"
      >
        Sample type
      </h2>

      <p
        *ngIf="!editing.type"
        class="mb-5 mt-1 flex items-center pr-6 text-xl text-black"
      >
        {{ sample.type }}
        <ng-container *ngIf="loading && sample.type !== form.value.type">
          <div class="ml-4">
            <app-loading size="h-4 w-4" />
          </div>
        </ng-container>
      </p>

      <ng-container *ngIf="sampleTypes$ | async; let sampleTypes">
        <ng-select
          *ngIf="editing.type"
          [items]="sampleTypes"
          formControlName="type"
          [searchable]="false"
          [clearable]="false"
          i18n-placeholder
          placeholder="Please select"
        >
        </ng-select>
      </ng-container>

      <app-button
        *ngIf="canEdit && !editing.type"
        [disabled]="loading && sample.type !== form.value.type"
        icon="edit"
        iconFill="blue"
        iconHeight="18px"
        classes="p-3 absolute top-1 right-0"
        (click)="editMode('type')"
        (keydown.enter)="editMode('type')"
      />

      <div *ngIf="editing.type" class="mb-4 mt-2">
        <app-button
          i18n-text
          text="Cancel"
          classes="py-2 px-6"
          (click)="clearEditMode()"
          (keydown.enter)="clearEditMode()"
        />
        <app-button
          i18n-text
          [disabled]="!!form.controls['type'].errors"
          text="Save"
          type="submit"
          (click)="updateSampleType()"
          (keydown.enter)="updateSampleType()"
          classes="py-2 px-6  ml-3"
        />
      </div>
    </div>

    <div class="relative max-w-sm" [formGroup]="form">
      <h2
        i18n
        class="text-sm font-light"
        [ngClass]="{
        'text-gray-600': !editing.matrix,
        'text-alpha-red': editing.matrix && form.controls['matrix'].touched && form.controls['matrix'].errors,
      }"
      >
        Sample matrix
      </h2>

      <p
        *ngIf="!editing.matrix"
        class="mb-5 mt-1 flex items-center pr-6 text-xl text-black"
      >
        {{ sample.matrix }}
        <ng-container *ngIf="loading && sample.matrix !== form.value.matrix">
          <div class="ml-4">
            <app-loading size="h-4 w-4" />
          </div>
        </ng-container>
      </p>

      <ng-select
        *ngIf="editing.matrix"
        [items]="matrixTypes"
        bindLabel="name"
        bindValue="id"
        formControlName="matrix"
        [searchable]="false"
        [clearable]="false"
        i18n-placeholder
        placeholder="Please select"
      >
      </ng-select>

      <app-button
        *ngIf="canEdit && !editing.matrix"
        [disabled]="loading && sample.matrix !== form.value.matrix"
        icon="edit"
        iconFill="blue"
        iconHeight="18px"
        classes="p-3 absolute top-1 right-0"
        (click)="editMode('matrix')"
        (keydown.enter)="editMode('matrix')"
      />

      <div *ngIf="editing.matrix" class="mb-4 mt-2">
        <app-button
          i18n-text
          text="Cancel"
          classes="py-2 px-6"
          (click)="clearEditMode()"
          (keydown.enter)="clearEditMode()"
        />
        <app-button
          i18n-text
          [disabled]="!!form.controls['matrix'].errors"
          text="Save"
          type="submit"
          (click)="updateSampleMatrix()"
          (keydown.enter)="updateSampleMatrix()"
          classes="py-2 px-6  ml-3"
        />
      </div>
    </div>

    <div class="relative max-w-sm" [formGroup]="form">
      <h2
        i18n
        class="text-sm font-light"
        [ngClass]="{
        'text-gray-600': !editing.suite,
        'text-alpha-red': editing.suite && form.controls['suite'].touched && form.controls['suite'].errors,
      }"
      >
        Sample suite
      </h2>

      <p *ngIf="!editing.suite" class="mb-5 mt-1 flex items-center pr-6 text-xl text-black">
        {{ sample.suite }}
        <ng-container *ngIf="loading && sample.suite !== form.value.suite">
          <div class="ml-4">
            <app-loading size="h-4 w-4" />
          </div>
        </ng-container>
      </p>

      <ng-select
        *ngIf="editing.suite"
        [items]="testSuites"
        formControlName="suite"
        [searchable]="false"
        [clearable]="false"
        bindLabel="name"
        bindValue="id"
        i18n-placeholder
        placeholder="Please select"
      >
      </ng-select>

      <app-button
        *ngIf="canEdit && !editing.suite"
        [disabled]="loading && sample.suite !== form.value.suite"
        icon="edit"
        iconFill="blue"
        iconHeight="18px"
        classes="p-3 absolute top-1 right-0"
        (click)="editMode('suite')"
        (keydown.enter)="editMode('suite')"
      />

      <div *ngIf="editing.suite" class="mb-4 mt-2">
        <app-button
          i18n-text
          text="Cancel"
          classes="py-2 px-6"
          (click)="clearEditMode()"
          (keydown.enter)="clearEditMode()"
        />
        <app-button
          i18n-text
          [disabled]="!!form.controls['suite'].errors"
          text="Save"
          type="submit"
          (click)="updateSampleSuiteCode()"
          (keydown.enter)="updateSampleSuiteCode()"
          classes="py-2 px-6  ml-3"

        />
      </div>
    </div>

    <div>
      <h2 i18n class="text-sm font-light text-gray-600">Sample status</h2>

      <p class="mb-5 mt-1 text-xl text-black">{{ sample.status }}</p>
    </div>

    <div *ngIf="sample.timestamp">
      <h2 i18n class="text-sm font-light text-gray-600">Sample timestamp</h2>

      <p class="mb-5 mt-1 text-xl text-black">
        {{ sample.timestamp * 1000 | date:'HH:mm' }}
      </p>
    </div>

    <div class="relative max-w-sm">
      <h2
        i18n
        class="text-sm font-light"
        [ngClass]="{
        'text-gray-600': !editing.notes,
        'text-alpha-red': editing.notes && form.controls['notes'].touched && form.controls['notes'].errors,
      }"
      >
        Sample notes
      </h2>

      <p
        *ngIf="!editing.notes"
        class="mb-5 mt-1 flex items-center pr-6 text-xl text-black"
      >
        {{ sample.notes }}
        <ng-container *ngIf="loading && sample.notes !== form.value.notes">
          <div class="ml-4">
            <app-loading size="h-4 w-4" />
          </div>
        </ng-container>
      </p>

      <app-input *ngIf="editing.notes" [form]="form" controlName="notes" />

      <app-button
        *ngIf="canEdit && !editing.notes"
        [disabled]="loading && sample.notes !== form.value.notes"
        icon="edit"
        iconFill="blue"
        iconHeight="18px"
        classes="p-3 absolute top-1 right-0"
        (click)="editMode('notes')"
        (keydown.enter)="editMode('notes')"
      />

      <div *ngIf="editing.notes" class="mb-4 mt-2">
        <app-button
          i18n-text
          text="Cancel"
          classes="py-2 px-6"
          (click)="clearEditMode()"
          (keydown.enter)="clearEditMode()"
        />
        <app-button
          i18n-text
          [disabled]="!!form.controls['notes'].errors"
          text="Save"
          type="submit"
          (click)="updateSampleNotes()"
          (keydown.enter)="updateSampleNotes()"
          classes="py-2 px-6  ml-3"
        />
      </div>
    </div>
  </div>

  <ng-template #showLoadingSpinner>
    <div class="mt-20 flex items-center justify-center">
      <app-loading />
    </div>
  </ng-template>
</div>
