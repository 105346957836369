import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventType, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { LoadingComponent } from './components/loading/loading.component';
import { selectUser } from './store/selectors/user.selector';

@Component({
    selector: 'app-root',
    imports: [RouterOutlet, CommonModule, LoadingComponent],
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  public loaded = false;
  private userSub: Subscription | null = null;
  private routerEventSub: Subscription | null = null;
  private readonly userPending$ = this.store.select(selectUser);

  public constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.routerEventSub = this.router.events.subscribe((event) => {
      if (!this.loaded && event.type === EventType.NavigationEnd) {
        if (
          !event?.url ||
          event.url === '/' ||
          (event.url && String(event.url).startsWith('/auth'))
        ) {
          this.loaded = true;
          this.unsubscribe();
        } else {
          this.userSub = this.userPending$.subscribe(({ pending }) => {
            if (!this.loaded && !pending) {
              this.loaded = true;
              this.unsubscribe();
            }
          });
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  private unsubscribe(): void {
    this.routerEventSub?.unsubscribe();
    this.userSub?.unsubscribe();
    this.routerEventSub = null;
    this.userSub = null;
  }
}
