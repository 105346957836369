import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Asset } from "../../interfaces/asset.interface";
import { AssetActions } from '../actions/asset.actions';

export interface AssetState extends EntityState<Asset> {
  error: unknown;
  pending: boolean;
}

const adapter: EntityAdapter<Asset> = createEntityAdapter<Asset>({
  selectId: (asset: Asset): string => asset.id,
});

export const initialState: AssetState = adapter.getInitialState({
  error: null,
  pending: false,
});

const AssetReducer = createReducer(
  initialState,
  on(AssetActions.clearState, (): AssetState => initialState),

  on(AssetActions.getForJob, (state): AssetState => ({ ...state, pending: true })),

  on(
    AssetActions.getForJobSuccess,
    (state, { assets }): AssetState =>
      adapter.upsertMany(assets, {
        ...state,
        error: null,
        pending: false,
      }),
  ),
  on(
    AssetActions.getForJobFailure,
    (state, { error }): AssetState => ({
      ...state,
      error,
      pending: false,
    }),
  ),
);

const { selectAll } = adapter.getSelectors();

export const selectAllAsset = selectAll;

export const reducer = (state: AssetState | undefined, action: Action) =>
  AssetReducer(state, action);
