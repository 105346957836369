import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { fromEvent, Subscription } from 'rxjs';

import { User, UserGroup } from '../../../../interfaces/user.interface';
import { CognitoActions } from '../../../../store/actions/cognito.actions';
import { selectUser } from '../../../../store/selectors/user.selector';
import { ADMIN_JOBS_PAGE_URL } from '../../../admin/jobs/jobs.page';
import { ADMIN_ORGANISATIONS_PAGE_URL } from '../../../admin/organisations/organisations.page';
import { ADMIN_USERS_PAGE_URL } from '../../../admin/users/users.page';
import { JOBS_AND_TESTS_PAGE_URL } from '../../../dashboard/jobs-and-tests/jobs-and-tests.page';
import { ORGANISATION_DETAILS_PAGE_URL } from '../../../dashboard/organisation-details/organisation-details.page';
import { PERSONAL_DETAILS_PAGE_URL } from '../../../dashboard/personal-details/personal-details.page';
import { USER_MANAGEMENT_PAGE_URL } from '../../../dashboard/user-management/user-management.page';

interface SidebarItem {
  readonly title: string;
  readonly iconUrl: string;
  readonly url: string;
}

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    imports: [CommonModule]
})
export class SidebarComponent implements OnInit, OnDestroy {
  public items: SidebarItem[] = [];

  public currentUrl = '';
  public isMobileView = false;
  public showMobileSidebar = false;
  public user: User | null = null;

  public user$ = this.store
    .select(selectUser)
    .subscribe(({ user }) => (this.user = user));

  private readonly resizeEvent = fromEvent(window, 'resize');
  private readonly routerNavigation$: Subscription;

  private readonly resizeEvent$: Subscription = this.resizeEvent.subscribe(
    () => {
      this.isMobileView = this.document.body.clientWidth <= 768;
      this.showMobileSidebar = false;
    },
  );

  public constructor(
    private readonly store: Store,
    private readonly router: Router,
    @Inject(DOCUMENT)
    private readonly document: Document,
  ) {
    if (this.user?.profile.group === UserGroup.ADMIN) {
      this.items = [
        {
          title: 'Organisations',
          iconUrl: 'organisation-details',
          url: 'admin/' + ADMIN_ORGANISATIONS_PAGE_URL,
        },
        {
          title: 'Users',
          iconUrl: 'user-management',
          url: 'admin/' + ADMIN_USERS_PAGE_URL,
        },
        {
          title: 'Jobs & Tests',
          iconUrl: 'jobs-and-tests',
          url: 'admin/' + ADMIN_JOBS_PAGE_URL,
        },
        {
          title: 'Personal details',
          iconUrl: 'personal-details',
          url: 'admin/' + PERSONAL_DETAILS_PAGE_URL,
        },
      ];
    } else if (
      this.user?.activeOrganisation.role === 'ADMIN' ||
      this.user?.activeOrganisation.role === 'OWNER'
    ) {
      this.items = [
        {
          title: 'Dashboard',
          iconUrl: 'organisation-details',
          url: ORGANISATION_DETAILS_PAGE_URL,
        },
        {
          title: 'User management',
          iconUrl: 'user-management',
          url: USER_MANAGEMENT_PAGE_URL,
        },
        {
          title: 'Jobs & tests overview',
          iconUrl: 'jobs-and-tests',
          url: JOBS_AND_TESTS_PAGE_URL,
        },
        {
          title: 'Personal details',
          iconUrl: 'personal-details',
          url: PERSONAL_DETAILS_PAGE_URL,
        },
      ];
    } else {
      this.items = [
        {
          title: 'Organisation details',
          iconUrl: 'organisation-details',
          url: ORGANISATION_DETAILS_PAGE_URL,
        },
        {
          title: 'Jobs & tests overview',
          iconUrl: 'jobs-and-tests',
          url: JOBS_AND_TESTS_PAGE_URL,
        },
        {
          title: 'Personal details',
          iconUrl: 'personal-details',
          url: PERSONAL_DETAILS_PAGE_URL,
        },
      ];
    }

    this.routerNavigation$ = this.router.events.subscribe(
      () => (this.currentUrl = this.getCurrentUrl()),
    );
  }

  public ngOnInit(): void {
    this.currentUrl = this.getCurrentUrl();
    this.isMobileView = this.document.body.clientWidth <= 768;
  }

  public ngOnDestroy(): void {
    this.resizeEvent$?.unsubscribe();
    this.routerNavigation$?.unsubscribe();
    this.user$?.unsubscribe();
  }

  public toggleSidebar(): void {
    this.showMobileSidebar = !this.showMobileSidebar;
  }

  public async goTo(path: string): Promise<void> {
    if (this.currentUrl !== path) {
      await this.router.navigateByUrl(path);
      this.currentUrl = this.getCurrentUrl();
      this.showMobileSidebar = false;
    }
  }

  public logout(): void {
    this.store.dispatch(CognitoActions.signOut());
  }

  private getCurrentUrl(): string {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_empty, first, second] = this.router.url.split('/');
    return first === 'admin' ? `admin/${second}` : first;
  }
}
