import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { APIResponse } from "../interfaces/api.interface";
import { Asset } from "../interfaces/asset.interface";
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {

  public constructor(private readonly api: ApiService) {}

  public getForJob(jobId: string, organisationId?: string, admin?: boolean ): Observable<APIResponse<Asset[]>> {
    const path: string = admin ? `admin/organisations/${organisationId}/orders/${encodeURIComponent(jobId)}/assets` :  `organisations/${organisationId}/orders/${encodeURIComponent(jobId)}/assets`

    return this.api.get<APIResponse<Asset[]>>( path );
  }
}
