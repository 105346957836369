import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ButtonComponent } from '../../../../../components/button/button.component';
import { IconComponent } from '../../../../../components/icon/icon.component';
import { InputComponent } from '../../../../../components/input/input.component';
import { ModalLayoutComponent } from '../../../../../components/modal-layout/modal-layout.component';
import { OrganisationInviteUser } from '../../../../../interfaces/organisation.interface';
import { AppState } from '../../../../../store';
import { OrganisationActions } from '../../../../../store/actions/organisation.actions';

@Component({
    selector: 'app-invite-user-modal',
    templateUrl: './invite-user.component.html',
    imports: [
        CommonModule,
        ButtonComponent,
        IconComponent,
        InputComponent,
        ModalLayoutComponent,
        FormsModule,
        ReactiveFormsModule,
    ]
})
export class InviteUserModalComponent implements OnDestroy {
  private _show = false;

  public get show(): boolean {
    return this._show;
  }

  @Input()
  public set show(value) {
    this._show = value;

    if (this._show && !this.organisationSubscription) {
      this.organisationSubscription = this.store
        .select('organisation')
        .subscribe((organisation) => {
          this.error = organisation.error;
          this.pending = organisation.pending;
        });
    }
  }

  @Input()
  public inviteAdmin = false;

  public view: 'initial' | 'response' = 'initial';

  @Output()
  public closed: EventEmitter<void | OrganisationInviteUser> =
    new EventEmitter<void | OrganisationInviteUser>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public error: any = null;

  public form = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  public pending = false;

  private organisationSubscription: Subscription | null = null;

  public constructor(private readonly store: Store<AppState>) {}

  public async ngOnDestroy(): Promise<void> {
    this.store.dispatch(OrganisationActions.clearErrorState());
    this.organisationSubscription?.unsubscribe();
    this.organisationSubscription = null;
    this.form.reset();
    this.view = 'initial';
  }

  public onSubmit(): void {
    if (this.form.valid) {
      const user: OrganisationInviteUser = {
        firstName: `${this.form.value.firstName}`,
        lastName: `${this.form.value.lastName}`,
        phoneNumber: `${this.form.value.phoneNumber}`,
        email: `${this.form.value.email}`,
        role: this.inviteAdmin ? 'ADMIN' : 'USER',
      };

      this.store.dispatch(OrganisationActions.inviteUser({ user }));
      this.view = 'response';
    }
  }

  public async close(): Promise<void> {
    this.closed.emit();
    this.ngOnDestroy();
  }
}
